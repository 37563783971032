import React, { useEffect, useState } from "react";
import "./FooterSection.scss";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import { FaFacebookSquare, FaTwitter } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { PiMapPinFill } from "react-icons/pi";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { GetServiceNameAPI } from "../../Constants/Api/Api";
import {
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineMail,
} from "react-icons/md";
const FooterSection = () => {
  const [social, setSocial] = useState();
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [service, setServices] = useState();
  useEffect(() => {
    GetServiceNameAPI()
      .then((res) => {
        setServices(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleRedirectService = (data) => {
    navigate(`/services/${data?.slug}`, {
      state: {
        data: data,
      },
    });
  };
  const handleNavClick = (path) => {
    navigate(path);
  };
  return (
    <div className="FooterSection">
      <div className="footerArea">
        <Container className="main-container">
          <Row>
            <Col md={4} className="section1_col">
              <FaFacebookSquare size={17} />
              &nbsp; &nbsp;
              <FaTwitter size={17} />
            </Col>
            <Col md={3}>
              <h4>Our Service Areas</h4>
              {service?.map((item, index) => {
                return (
                  <React.Fragment key={item.id}>
                    <li
                      onClick={() => handleRedirectService(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <MdOutlineKeyboardDoubleArrowRight />
                      &nbsp;{item.name}
                    </li>
                  </React.Fragment>
                );
              })}
              <li
                style={{
                  textTransform: "capitalize",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <MdOutlineKeyboardDoubleArrowRight />
                Sitemap
              </li>
            </Col>
            <Col md={2}>
              <h4>Quick Links</h4>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => handleNavClick("/")}
              >
                <MdOutlineKeyboardDoubleArrowRight />
                &nbsp;HOME
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => handleNavClick("/about-us")}
              >
                <MdOutlineKeyboardDoubleArrowRight />
                &nbsp;ABOUT US
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => handleNavClick("/blogs")}
              >
                <MdOutlineKeyboardDoubleArrowRight />
                &nbsp;BLOGS
              </li>
              <li
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => handleNavClick("/contact-us")}
              >
                <MdOutlineKeyboardDoubleArrowRight />
                &nbsp;CONTACT US
              </li>
              {/* <li
                style={{ border: "none", cursor: "pointer" }}
                onClick={() => handleNavClick("/how-to-delete-account")}
              >
                <MdOutlineKeyboardDoubleArrowRight />
                <span style={{ fontSize: "10px" }}>
                  &nbsp;How to Delete Account
                </span>
              </li> */}
            </Col>
            <Col md={3}>
              <h4>Contact Info</h4>
              <li className="li_desc">
                <div>
                  <PiMapPinFill />
                </div>
                <div>
                  Nunavut Moving & Services Building 1088 C8 P.O. BOX 62,
                  IQALUIT, NU X0A 0H0
                </div>
              </li>
              <li className="li_desc">
                <div>
                  <FaPhoneSquareAlt />
                </div>
                <div>
                  <a
                    href="tel:(867) 979-0707"
                    itemprop="Phone"
                    className="link"
                  >
                    Phone : (867) 979-0707
                  </a>
                </div>
              </li>
              <li className="li_desc" style={{ border: "none" }}>
                <div>
                  <MdOutlineMail />
                </div>
                <div>
                  <a href="mailto:nunamoving@gmail.com" className="link">
                    Email : nunamoving@gmail.com
                  </a>
                </div>
              </li>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="sec1">
        <p>
          @All Rights Reserved | {currentYear} |{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/privacy-policy")}
          >
            Privacy Policy
          </span>
          | Terms & Conditions | Crafted By blockcod.Com
        </p>
      </div>
    </div>
  );
};
export default FooterSection;

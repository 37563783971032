import axios from "axios";
const BASE_URL = "https://node.nunamoving.com:8080/api/v1/";
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
const UserToken = localStorage.getItem("UserLoginToken")
// LoginAPI
export const UserLoginAPI = async (formdata) => {
  let headers = {
    timezone: userTimeZone,
    role_id: "6",
    Accept: "application/json",
  }
  return await axios.post(`${BASE_URL}user/auth/login`, formdata, { headers });
};
// LoginASGuestAPI
export const UserLoginAsGuestAPI = async () => {
  let headers = {
    // timezone: userTimeZone,
    role_id: "6",
    Accept: "application/json",
  }
  return await axios.post(`${BASE_URL}user/auth/guestLogin`, null, { headers });
};
// UserRegisterAPI
export const UserRegisterAPI = async (formdata) => {
  return await axios.post(`${BASE_URL}user/auth/register`, formdata);
};
// SendOTPAPI
export const SendOTPAPI = async (formdata) => {
  return await axios.post(`${BASE_URL}user/auth/otp`, formdata);
};
// VerifyOTPAPI
export const VerifyOTPAPI = async (formdata) => {
  return await axios.post(`${BASE_URL}user/auth/verify-otp`, formdata);
};
// ForgotPassword
export const ForgotPasswordApi = async (formdata) => {
  return await axios.post(`${BASE_URL}user/auth/forgot-password`, formdata);
};
// ServiceAPI
export const GetServiceNameAPI = async () => {
  return await axios.get(`${BASE_URL}service/getServiceName`);
};
// serviceDetils
export const ServiceDetailsAPI = async (service_slug) => {
  let config = {
    name: service_slug
  }
  return await axios.post(`${BASE_URL}service/getServiceFromSlug`, config);
};
// Create Booking
export const CreateBookingWithLoginAPI = async (formData, token) => {
  let headers = {
    "x-access-token": token,
    role_id: "6",
  }
  return await axios.post(`${BASE_URL}booking/createBooking`, formData, { headers });
};
// Create Booking
export const CreateBookingWithoutLoginAPI = async (formData, token) => {
  // console.log(formData, token,"check booking data")
  let headers = {
    "x-access-token": token,
    role_id: "6",
  }
  return await axios.post(`${BASE_URL}booking/createGuestBooking`, formData, { headers });
};
// Booking History
export const BookingHistoryAPI = async (value) => {
  let config = {
    booking_status: value
  }
  let headers = {
    "x-access-token": UserToken,
    role_id: "6",
  }
  return await axios.post(`${BASE_URL}booking/getAllUserBooking`, config, { headers });
};
// Product
export const GetProductAPI = async () => {
  return await axios.get(`${BASE_URL}product/getAllProducts`);
};


// GetProductAPIwithToken
export const GetProductAPIwithToken = async () => {
  let headers = {
    "x-access-token": UserToken,
    role_id: "6",
  }
  return await axios.get(`${BASE_URL}order/findProductsInCart`,{headers});
};
// Add To Cart
export const AddToCartAPI = async (value1, value2, UserToken) => {
  let config = {
    product_id: value1,
    quantity: value2
  }
  let headers = {
    "x-access-token": UserToken,
    role_id: "6",
  }
  return await axios.post(`${BASE_URL}order/addItemToCart`, config, { headers });
};
// Get all Product
export const GetAllProductsInCart = async (UserToken) => {
  let headers = {
    "x-access-token": UserToken,
    role_id: "6",
  }
  return await axios.get(`${BASE_URL}order/getAllProductsInCart`, { headers });
};
// Add Decrease Operation
export const DecreasProduct = async () => {
  let headers = {
    "x-access-token": UserToken,
    role_id: "6",
  }
  return await axios.get(`${BASE_URL}order/getAllProductsInCart`, { headers });
};
// Remove Item In Cart
// export const RemoveProduct = async (value) => {
//   let config = {
//     cart_id: value
//   }
//   let headers = {
//     "x-access-token": UserToken,
//     role_id: "6",
//   }
//   return await axios.delete(`${BASE_URL}order/removeProduct`, config, { headers });
// };
export const RemoveProduct = async (value) => {
  let config = {
    data: { cart_id: value },  // Use the 'data' property for the request body
    headers: {
      "x-access-token": UserToken,
      role_id: "6",
    }
  };
  try {
    const response = await axios.delete(`${BASE_URL}order/removeProduct`, config);
    return response.data;
  } catch (error) {
    console.error('Error removing product:', error);
    throw error;  // Re-throw the error for further handling
  }
};
// Change Product Quantity
export const updateProductQuantity = async (value1, value2) => {
  let config = {
    cart_id: value1,
    action: value2
  }
  let headers = {
    "x-access-token": UserToken,
    role_id: "6",
  }
  return await axios.post(`${BASE_URL}order/updateProductQuantity`, config, { headers });
};
// Create Order
export const CreateOrderAPI = async (value, UserToken) => {
  let config = {
    address: value,
  }
  let headers = {
    "x-access-token": UserToken,
    role_id: "6",
  }
  return await axios.post(`${BASE_URL}order/createOrder`, config, { headers });
};
// Get All Order
export const GetAllOrders = async () => {
  let headers = {
    "x-access-token": UserToken,
    role_id: "6",
  }
  return await axios.get(`${BASE_URL}order/getAllUserOrders`, { headers });
};
// get all blogs
export const GetAllBlogs = async (PER_PAGE, page) => {
  return await axios.get(`${BASE_URL}blog/getBlogs?limit=${PER_PAGE}&page=${page}`)
}
// get blogs by id
export const GetBlogsById = async (blog_id) => {
  return await axios.get(`${BASE_URL}/blog/findBlogById/${blog_id}`)
}
// SendMessage
export const SendMessageAPI = async (formData, token) => {
  let headers = {
    "x-access-token": token,
    role_id: "6",
  }
  return await axios.post(`${BASE_URL}firebase/sendMessage`, formData, { headers });
};
// chatmessage
export const GetAllUserMessages = async (token) => {
  let config = {
    receiverUserId: 8
  }
  let headers = {
    "x-access-token": token,
    role_id: "6",
  }
  return await axios.post(`${BASE_URL}firebase/getAdminMessages`, config, { headers });
};
// contact-us
export const PostQuery = async (formdata) => {
  return await axios.post(`${BASE_URL}quote/createQuote`, formdata);
};
//Address
export const GetConatct = async () => {
  return await axios.get(`${BASE_URL}contactUs/getContactUs`);
};
// ************Getprofile API****************
export const GetProfile = async (token) => {
  try {
    const response = await axios.get(BASE_URL + "user/profile", {
      headers: {
        "x-access-token": token,
        role_id: 6
      },
    });
    return response.data;
  } catch (error) {
    console.error("API response error:", error);
    throw error;
  }
};
// ************Profile Edit API****************
export const UserEditProfileAPI = async (formdata, Value) => {
  // };
  return await axios.put(BASE_URL + "user/updateUserProfile", formdata, {
    headers: {
      "x-access-token": Value,
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      role_id: 6
    },
  });
};
// ************Change Password API****************
export const ChangePasswordAPI = async (value1, value2, value3, token) => {
  let config = {
    old_password: value1,
    new_password: value2,
    confirm_password: value3,
  };
  return await axios.post(BASE_URL + "user/auth/reset-password", config, {
    headers: {
      "x-access-token": token,
    },
  });
};
// Add Address
export const AddAddressAPI = async (address, token) => {
  let config = {
    address: address,
  };
  return await axios.post(BASE_URL + "user/addNewAddress", config, {
    headers: {
      "x-access-token": token,
      role_id: 6
    },
  });
};

export const DeleteAccountApi = async (token) => {

  return await axios.delete(BASE_URL + "user/deactivate",  {
    headers: {
      "x-access-token": token,
      role_id: 6
    },
  });
};
import logo from "./logo.svg";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeaderSection from "./Component/HeaderSection/HeaderSection";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Blogs from "./Component/Blogs/Blogs";
import FooterSection from "./Component/FooterSection/FooterSection";
import Layout from "./Component/Layout/Layout";
import AboutUSComponent from "./Component/AboutUSComponent/AboutUSComponent";
import ServiceDetails from "./Component/ServiceDetails/ServiceDetails";
import BlogDetails from "./Component/BlogDetails/BlogDetails";
import ContactUs from "./Component/ContactUs/ContactUs";
import SignUp from "./Component/Signup/Signup";
import Signin from "./Component/Signin/Signin";
import BookingHistory from "./Component/BookingHistory/BookingHistory";
import Orders from "./Component/Orders/Orders";
import ProfileComponent from "./Component/Profile/ProfileComponent";
import ForgotPassword from "./Component/Forgot/forgotPassword";
import ProductComponent from "./Component/ProductComponent/ProductComponent";
import CheckoutPage from "./Component/CheckoutPage/CheckoutPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy/Privacy";
import VerifyOTP from "./Component/Forgot/VerifyOtp";
import DeleteAccount from "./Pages/DeleteAccount/DeleteAccount";
import GlobalChatButton from "./Component/GlobalChatButton";
function App() {
  return (
    <div>
      <BrowserRouter>
        <HeaderSection />
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route
            exact
            path="/how-to-delete-account"
            element={<DeleteAccount />}
          />
          <Route
            exact
            path="/blogs"
            element={
              <Layout>
                <Blogs />
              </Layout>
            }
          />
          <Route
            exact
            path="/about-us"
            element={
              <Layout>
                <AboutUSComponent />
              </Layout>
            }
          />
          <Route
            exact
            path="/services/:slug"
            element={
              <Layout>
                <ServiceDetails />
              </Layout>
            }
          />
          <Route
            exact
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />
          <Route
            exact
            path="/blogs-details"
            element={
              <Layout>
                <BlogDetails />
              </Layout>
            }
          />
          <Route exact path="/booking-history" element={<BookingHistory />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/profile-edit" element={<ProfileComponent />} />
          <Route
            exact
            path="/product-shopping"
            element={<ProductComponent />}
          />
          <Route exact path="/chekcout-order" element={<CheckoutPage />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route
            exact
            path="/how-to-delete-account"
            element={<DeleteAccount />}
          />
          <Route exact path="/verifyotp" element={<VerifyOTP />} />
        </Routes>
        <GlobalChatButton/>
        <FooterSection />
      </BrowserRouter>
    </div>
  );
}
export default App;

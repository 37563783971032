import React from 'react'
import "./CardComponent.scss"
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Image_URL } from '../../Constants/host'
const CardComponent = ({ data }) => {
    const navigate = useNavigate()
    const handleRedirectService = (data) => {
        navigate(`/services/${data?.slug}`, {
            state: {
                data: data,
            }
        });
    }
    return (

        <div className='CardComponent'>
            <div className='card custom_card'>
                <img src={`${Image_URL}/${data?.service_attachments[0]?.file_name}`} crossOrigin='anonymous' class="card-img-top image" alt="..." />
                <div class="card-body">
                    <h4 >{data?.name}</h4>
                </div>
                <div className='buttonSection'>
                    <Button className="buttontheme" variant="primary" onClick={() => handleRedirectService(data)}>Read More</Button>
                </div>
            </div>
        </div>
    )
}
export default CardComponent
